<template>
    <b-button-group class="w-100">
        <b-button variant="light" @click="onStepBackward" :size="size" :disabled="disabled"><i class="fas fa-minus"></i></b-button>
        <b-form-input type="number" class="m-1 text-center" min="0.5" @change="onInput" :size="size" :disabled="disabled" :step="0.5"
                      :value="value"></b-form-input>
        <b-button variant="light" @click="onStepForward" :size="size" :disabled="disabled"><i class="fas fa-plus"></i></b-button>
    </b-button-group>
</template>

<script>
    export default {
        name: "SpinButton",
        data: () => {
            return {
            };
        },
        props: {
            value: {
                type: Number,
                default: 0
            },
            step: {
                type: Number,
                default: 1
            },
            min: {
                type: Number,
                default: null
            },
            max: {
                type: Number,
                default: null
            },
            size: {
                type: String,
                default: 'md'
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            onInput(input) {
                input = this.check(input);
                this.$emit('input', input);
                if (input != this.value) this.$emit('change', input);
            },
            onStepForward($e) {
                console.log("LWSpinButton.vue:onStepForward:e",$e);
                let newValue = parseFloat(this.value);
                newValue += parseFloat(this.step);
                newValue = parseFloat((newValue).toFixed(4));
                newValue = this.check(newValue);
                this.$emit('input', newValue);
                if (newValue != this.value) this.$emit('change', newValue);
                this.$emit('step-forward', newValue);
            },
            onStepBackward($e) {
                console.log("LWSpinButton:onStepBackward:e",$e);
                let newValue = parseFloat(this.value);
                newValue -= parseFloat(this.step);
                newValue = parseFloat((newValue).toFixed(4));
                newValue = this.check(newValue);
                this.$emit('input', newValue);
                if (newValue != this.value) this.$emit('change', newValue);
                this.$emit('step-backward', newValue);
            },
            check(value) {
                if (isNaN(value)) value = 0;
                if (this.min != null && value < this.min) {
                    value = this.min;
                    // this.value = this.min;
                }
                if (this.max != null && value > this.max) {
                    value = this.max;
                    // this.value = this.max;
                }
                return value;
            }
        }
    }
</script>

<style scoped>
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>